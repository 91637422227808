
export const BILLING_ADDRESS_OPTION = 'billingAddressOption'
export const DIFFERENT_BILLING_ADDRESS_DATA  = 'differentBillingAddressData'
export const BILLING_FIRST_NAME = 'billing_firstName'
export const BILLING_LAST_NAME = 'billing_lastName'
export const BILLING_ADDRESS = 'address'
export const BILLING_UNIT_NUMBER = 'billing_unitNumber'
export const BILLING_CITY = 'billing_city'
export const BILLING_STATE_OR_TERITORY = 'billing_stateOrTerritory'
export const BILLING_POSTCODE = 'billing_postcode'

export const PAYMENT_METHOD_SELECTION = 'paymentMethodSelection'

export const CREDITCARD_PAYMENT_CARD_HOLDER_FIRST_NAME = 'paymentCreditCard_Cardholder_FirstName'
export const CREDITCARD_PAYMENT_CARD_HOLDER_LAST_NAME = 'paymentCreditCard_Cardholder_LastName'
export const CREDITCARD_PAYMENT_CARD_HOLDER_CARD_NUMBER = 'paymentCreditCard_Cardholder_CardNumber'
export const CREDITCARD_PAYMENT_CARD_HOLDER_EXPIRY_DATE = 'paymentCreditCard_Cardholder_ExpiryDate'
export const CREDITCARD_PAYMENT_CARD_HOLDER_CVC_CVV = 'paymentCreditCard_Cardholder_CVC_CVV_Number'
// This name is inconsistent to avoid password managers trying to autofill with incorrect credit card information
export const CREDITCARD_PAYMENT_DATE_OF_DEDUCTION = 'paymentCC_DateOfDeduction'
export const CREDITCARD_PAYMENT_FREQUENCY_OF_DEDUCTION = 'paymentCC_FrequencyOfDeduction'
export const CREDITCARD_PAYMENT_AGREE_TERM_AND_CONDITION = 'paymentCreditCard_TermAndCondition'

export const CREDITCARD_BPOINT_AUTHKEY = 'paymentCreditCard_BPoint_AuthKey'

export const CREDITCARD_FATZEBRA_AUTHKEY = 'paymentCreditCard_FatZebra_AuthKey'
export const CREDITCARD_FATZEBRA_TOKEN = 'paymentCreditCard_FatZebra_Token'

export const CREDITCARD_PAYWAY_TOKEN = 'paymentCreditCard_PayWay_Token'

export const BANKACCOUNT_PAYMENT_ACCOUNT_NAME = 'paymentBankAccount_AccountName'
export const BANKACCOUNT_PAYMENT_BSB = 'paymentBankAccount_BSB'
export const BANKACCOUNT_PAYMENT_ACCOUNT_NUMBER = 'paymentBankAccount_AccountNumber'
export const BANKACCOUNT_PAYMENT_DATE_OF_DEDUCTION = 'paymentBankAccount_DateOfDeduction'
export const BANKACCOUNT_PAYMENT_FREQUENCY_OF_DEDUCTION = 'paymentBankAccount_FrequencyOfDeduction'
export const BANKACCOUNT_AGREE_TERM_AND_CONDITION = 'paymentBankAccount_TermAndCondition'

export const PAYROLL_DEDUCTION_HAS_NUMBER = 'payrollDeduction_HasNumber'
export const PAY_DEDUCTION_PAYMENT_ELOTRONIC_SIGNATURE = 'paymentPayDeduction_ElectronicSignature'
export const PAY_DEDUCTION_PAYMENT_NUMBER = 'paymentPayDeduction_PayrollNumber'

export const DIRECT_DEBIT_AGREEMENT = 'directDebitAgreement'

export const CREDITCARD_PAYMENT_TAB_DATA = 'creditCardTabData'
export const BANKACCOUNT_PAYMENT_TAB_DATA = 'bankAccountTabData'
export const PAY_DEDUCTION_PAYMENT_TAB_DATA = 'payDeductionTabData'