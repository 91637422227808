export const FIRST_NAME = 'firstName'
export const LAST_NAME = 'lastName'
export const PHONE_NUMBER = 'phoneNumber'
export const EMAIL = 'email'
export const SUBURB_WORK = 'suburbWork'
export const EMPLOYER = 'employer'
export const WORK_PLACE_POSTCODE = 'workPostcode'
export const CANT_FIND_SUBURB_SELECTED = 'cantFindSuburb'
export const STATE_FROM_SUBURB = 'stateFromSuburb'
export const EMPLOYER_SELECTED_OR_TYPED = 'employerSelectedOrTyped'
export const PAYROLL_DEDUCTION_REQUIRED = 'payrollDeductionRequired'