//https://github.com/gatsbyjs/gatsby/issues/12399
import 'core-js'
import 'babel-polyfill'
import { polyfill } from 'es6-promise'
global.Promise = require('es6-promise').Promise

export const onClientEntry = () => {

}
polyfill()

export { wrapRootElement } from './gatsby-wrapper'
